<template>
  <div class="page exchange-page">
    <HeadBar class="headbar" title="兑换VIP"></HeadBar>
    <div class="exchange">
      <div class="exchange-input">
        <input
          v-model="cdkey"
          placeholder="请输入16位兑换码，注意区分大小写"
          maxlength="16"
          type="text"
          @input="handleFilterInput"
        />
      </div>
      <div
        @click="handleExchange"
        class="exchange-btn"
        :class="enable && 'enable'"
      >
        确认兑换
      </div>
      <div class="exchange-rule">
        <div class="title">VIP兑换规则说明:</div>
        <div class="content">
          <div>1、兑换成功后可获得相应天数的雷石ktv会员服务。</div>
          <div>2、兑换码一次只能兑换一张，不可叠加兑换。</div>
        </div>
      </div>
      <Record from="exchange" />
    </div>
  </div>
  <ExchangeVipModal v-if="isShowExchangeResultVipModal" :date="date" @close="handleCloseExchangeResultVipModal" />
</template>

<script>
import get from 'lodash/get'
import { useStore } from 'vuex'
import { ref, computed } from 'vue'
import { Dialog } from 'vant'
import Toast from '@/utils/toast'
import Record from '@/pages/mine/components/record'
import ExchangeVipModal from '@/components/teleport/exchange-vip'


export default {
  name: 'Exchange',
  components: {
    Record,
    ExchangeVipModal,
  },
  setup() {
    // const router = useRouter()
    const store = useStore()

    let cdkey = ref('')
    let date = ref('')
    const userInfo = computed(() => store.state.userInfo)
    const macAddress = computed(() => store.state.macAddress)
    const enable = ref(false)

    // let isShowVipModal = ref(false)
    let isShowExchangeResultVipModal = ref(false)

    const handleFilterInput = () => {
      if (!cdkey.value) return
      // eslint-disable-next-line
      if (!/^[A-Za-z0-9]+$/gi.test(cdkey.value)) {
        Toast('兑换码格式错误，请检查后重新输入')
        cdkey.value = ''
        enable.value = false
      } else if (cdkey.value.length === 16) {
        enable.value = true
      }
    }

    const handleExchange = async () => {
      if (!enable.value) return
      if (cdkey.value.length != 16) {
        Toast('请输入16位兑换码')
        return
      }
      const res = await store.dispatch('exchangeVip', {
        cdkey: cdkey.value,
        mac_id: macAddress.value,
      })
      date.value = `${res.data?.start_time.split(' ')[0]}-${res.data?.end_time.split(' ')[0]}`
      if (get(res, 'errmsg')) {
        Dialog.confirm({
          title: '兑换成功',
          confirmButtonText: '知道了',
          showCancelButton: false,
          message: `恭喜您，成功开通VIP\n会员有效期：${
            res.data.start_time.split(' ')[0]
          }-${res.data.end_time.split(' ')[0]}`,
        }).then(() => {
          cdkey.value = ''
          // handleShowVipModal()
          handleShowExchangeResultVipModal()
          store.dispatch('getCarplayInfo')
        })
      }
    }

    const handleCloseExchangeResultVipModal = () => {
      isShowExchangeResultVipModal.value = false
    }

    const handleShowExchangeResultVipModal = () => {
      isShowExchangeResultVipModal.value = true
    }

    return {
      cdkey,
      userInfo,
      date,
      isShowExchangeResultVipModal,
      handleExchange,
      handleFilterInput,
      handleCloseExchangeResultVipModal,
      enable,
    }
  },
}
</script>

<style lang="stylus" scoped>
.exchange-page
  color #1D1D1F
  padding 150px 180px 0
.exchange
  margin-top 74px
  height calc(100vh - 164px)
  overflow-y scroll
  ::-webkit-scrollbar
    display none
  &-input
    display flex
    font-size 32px
    margin 0 auto
    color #1D1D1F
    @media screen and (max-width 1200px) and (min-height 1000px)
      padding-left 0 !important
    input
      margin 0 auto
      width 1000px
      height 128px
      border-radius 4px
      border 2px solid #1D1D1F33
      padding 0 30px
      @media screen and (max-width 1200px) and (min-height 1000px)
        width 832px
        font-size 26px
  &-btn
    border-radius: 4px;
    background: rgba(29, 29, 31, 0.1)
    backdrop-filter: blur(100px);
    width: 300px;
    height: 80px;
    font-size: 28px;
    color rgba(29, 29, 31, 0.3)
    display flex
    justify-content center
    align-items center
    margin 60px auto 0
    font-size var(--font-size-medium)
    &.enable
      background: #DBAE6A
      color #1D1D1F
    @media screen and (max-width 1200px) and (min-height 1200px)
      width: 240px;
      height: 64px;
      font-size: 22px;
      margin-top 48px;
  &-rule
    font-size 28px
    margin-top 120px
    color #1D1D1FCC
    .title
      font-size: 32px;
      color #1D1D1F !important
    .content
      font-weight 400
    @media screen and (max-width 1200px) and (min-height 1200px)
      margin-top 160px
      font-size: 22px;
      .title
        font-size: 26px;
@media screen and (min-width: 1300px)
  .inputPositionFixed
    position fixed
    top 38vh
.page
  height 100vh
  overflow hidden
  .headbar
    background: none
</style>

