<template>
  <CommonTeleportModal @cancel="close">
    <div class="activity-exchange-modal-vip" v-show="isShow">
      <div @click="close" class="activity-exchange-modal-vip-close"></div>
      <p class="activity-exchange-modal-vip-date">有效期：{{ date }}</p>
      <div class="activity-exchange-modal-vip-bottom">
        <div class="activity-exchange-modal-vip-code">
          <img :src="qrCodeURL" alt="">
          <!-- <span>立即扫码购买</span> -->
        </div>
      </div>
    </div>
  </CommonTeleportModal>
</template>
<script>
import { ref, onBeforeMount } from 'vue'
import CommonTeleportModal from './../common/index.vue'
import { getCarplayInfo } from '@/service/carplay-info'
import useQRCode from '@/composables/useQRCode'
import Toast from '@/utils/toast'
import get from 'lodash/get'

export default {
  name: 'ActivityExchangeVipModal',
  components: {
    CommonTeleportModal,
  },
  props: {
    date: {
      type: String,
      default: ''
    }
  },
  setup(_, { emit }) {

    const { getQRCodeURL } = useQRCode()
    let qrCodeURL = ref('https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png')

    const getVipQrcode = async () => {
      const { data } = await getCarplayInfo()
      if (get(data, 'pay_qr', '')) {
        const qrCodeData = await getQRCodeURL(`${data.pay_qr}&renew=1`)
        if (qrCodeData) {
          qrCodeURL.value = qrCodeData
        }
        return
      }
      Toast('未获取到登录二维码')
    }

    onBeforeMount(getVipQrcode)

    let isShow = ref(true)

    const show = () => {
      isShow.value = true
    }

    const close = () => {
      isShow.value = false
      emit('close')
    }

    return {
      qrCodeURL,
      isShow,
      show,
      close,
    }
  }
}
</script>
<style lang="stylus" scoped>
.activity-exchange-modal-vip
  padding-top 122px
  position relative
  width 707px
  height 821px
  // border-radius 20px
  background url('https://qncweb.ktvsky.com/20241025/vadd/ff3d9f8b36d4f57fbe1cfbce6eae888d.png') no-repeat
  background-size 100% 100%
  background-position center
  color rgba(255, 51, 100, 1)
  display flex
  flex-direction column
  align-items center
  &-date
    width 100%
    height 24px
    line-height 24px
    text-align center
    color rgba(0, 0, 0, 0.6)
    font-size 20px
    position absolute
    top 285px
    left 0px
  &-close
    width 70px
    height 70px
    position absolute
    top 32px
    right 0px
  &-bottom
    width 100%
    height 160px
    display flex
    justify-content right
    position absolute
    bottom 282px
    left 0
  &-code
    width 160px
    height 160px
    margin-right 68px
    background #fff
    border-radius 8px
    display flex
    flex-direction column
    align-items center
    img
      width 150px
      height 150px
      margin-top 10px
    // span
    //   height 34px
    //   line-height 34px
    //   font-weight 400
    //   font-size 24px
    //   color rgba(0, 0, 0, 0.8)
    //   margin-top 12px
</style>
