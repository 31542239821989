<template>
  <div class="record">
    <div class="record-title">兑换记录</div>
    <div class="record-list">
      <LoadMore
        v-if="records.length"
        @load-more="fetchData"
        safeAreaHeight="12.6991vw"
      >
        <div class="record-item" v-for="(item, index) in records" :key="index">
          <div class="left">
            <h3>{{ item.pkg_desc }}</h3>
            <p>{{ item.create_time }}</p>
          </div>
          <div class="right">{{ item.days }} 天</div>
        </div>
        <div class="record-bottom">
          <p v-if="records.length > 10">已加载全部</p>
        </div>
      </LoadMore>
      <div v-else-if="!isRequest" class="record-empty">
        <svg
          width="90"
          height="90"
          viewBox="0 0 90 90"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.3">
            <rect
              x="11"
              y="11"
              width="68"
              height="68"
              rx="34"
              stroke="#1D1D1F"
              stroke-width="4"
            />
            <circle cx="45" cy="60" r="4" fill="#1D1D1F" />
            <path
              d="M41.0528 31.4226C40.7852 29.0652 42.6293 27 45.0018 27C47.3743 27 49.2184 29.0652 48.9509 31.4226L47.0505 48.1668C46.9323 49.2088 46.0506 49.996 45.0019 49.996C43.9532 49.996 43.0716 49.2088 42.9533 48.1668L41.0528 31.4226Z"
              fill="#1D1D1F"
            />
          </g>
        </svg>
        <p>暂无兑换记录</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getExchangeHistory } from '@/service/vip'
import { computed, onBeforeMount, ref } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'Record',
  setup() {
    const store = useStore()
    const userInfo = computed(() => store.state.userInfo)

    let records = ref([])
    let p = 1
    let isRequest = false

    const handleGetExchangeHistoryList = async () => {
      if (isRequest) {
        return
      }
      isRequest = true

      const historyResponseData = await getExchangeHistory(
        userInfo.value.unionid,
        p
      )
      if (historyResponseData.length) {
        records.value = records.value.concat(historyResponseData)
        p++
      }
      isRequest = false
    }

    onBeforeMount(handleGetExchangeHistoryList)

    return {
      records,
      handleGetExchangeHistoryList,
    }
  },
}
</script>

<style lang="stylus" scoped>
.record
  &-title
    padding 100px 0 25px
    margin-bottom 28px
    font-size var(--font-size-large)
    color rgba(29, 29, 31, 0.6)
    border-bottom 2px solid rgba(255, 255, 255, 0.1)
    @media screen and (max-width 1200px) and (min-height 1200px)
      padding-top 200px
      font-size 26px
  .record-item
    display flex
    justify-content space-between
    align-items center
    height 117px
    h3
      font-size var(--font-size-large)
      color rgba(29, 29, 31, 1)
    p
      font-size 28px
      color rgba(29, 29, 31, 0.4)
    .right
      font-size: 40px;
      color rgba(29, 29, 31, 0.8)
    @media screen and (max-width 1200px) and (min-height 1200px)
      h3
        font-size 26px
      p
        font-size: 20px;
      .right
        font-size: 26px;
  &-bottom
    margin-top 22px
    color rgba(255, 255, 255, 0.4)
    font-size 28px
    text-align center
  &-empty
    width 150px
    font-size: 24px;
    background none
    padding 30px 0 388px
    margin 0 auto !important
    text-align center
    display flex
    flex-direction column
    justify-content center
    align-items center
    p
      color rgba(29, 29, 31, 1)
    svg
      width 90px
      height 90px
      margin-bottom 10px
</style>
